<template>
	<div
		v-if="items"
		:class="['cards', `cards-${layout}`]"
	>
		<ul>
			<li
				v-for="item in items"
				:key="item.name"
			>
				<div
					:class="['cards-item', {'is-active': active && active.dataset.name === item.name }]"
					:data-name="item.name"
				>
					<h4
						class="cards-title"
						@click="onOpenModal(item)"
					>
						{{ item.name }}
						<span v-if="item.alias">{{ item.alias }}</span>
					</h4>

					<figure
						class="cards-icon"
						@click="onOpenModal(item)"
					>
						<BaseIcon :src="`animals/${item.slug}.svg`" />
					</figure>

					<div class="cards-meta">
						<abbr
							v-if="item.diet"
							:title="item.diet"
							@click="changeFilter('diet', item.diet, true)"
						>
							<BaseIcon :src="getDietIcon(item.diet)" />
						</abbr>
						<abbr
							v-for="meta in taxa.filter((el) => has(el.name, item.taxon))"
							:key="meta.name"
							:title="meta.name"
							@click="changeFilter('taxon', meta.name, true)"
						>
							<BaseIcon :src="getTaxaIcon(meta)" />
						</abbr>
					</div>

					<AppAges
						:item="item"
						:periods="getPeriods(item.period)"
					/>
				</div>
			</li>
		</ul>
	</div>
</template>

<script>
import AppAges from './AppAges';
import {FilterMixin, ModalMixin} from '@/mixins';
import {has, isVisible, push} from '@schascha/brabbelback';
import {taxa} from '@/data/filter';


export default {
	name: 'AppCards',
	components: {
		AppAges
	},
	mixins: [FilterMixin, ModalMixin],
	props: {
		items: {
			type: Array,
			default: () => []
		},
		layout: {
			type: String,
			default: 'grid',
			validation(value) {
				return ['grid', 'slider'].includes(value);
			}
		}
	},
	data() {
		return {
			taxa: taxa,
			active: null
		};
	},
	computed: {
		periods() {
			return this.$store.state.periods;
		}
	},
	mounted() {
		if (this.layout === 'grid') {
			document.addEventListener('scroll', this.onScroll);
		}
	},
	beforeDestroy() {
		if (this.layout === 'grid') {
			document.removeEventListener('scroll', this.onScroll);
		}
	},
	methods: {
		getDietIcon(diet) {
			return `filter/${this.$store.getters.getDiet(diet).slug}.svg`;
		},
		getTaxaIcon(taxa) {
			return `filter/${taxa.slug}.svg`;
		},
		getPeriods(period) {
			const erathems = [];
			this.periods.forEach((el) => has(period, el.name) && push(erathems, el.erathem));
			return this.periods.filter((el) => has(erathems, el.erathem));
		},
		has(obj, value) {
			return has(obj, value);
		},
		onScroll() {
			// Return on large screens and reset active element
			if (window.innerWidth > 608) {
				this.active = null;
				return;
			}

			// Return if the active element is still visible
			if (isVisible(this.active)) {
				return;
			}

			// Otherwise find first visible card item and set active
			const
				items = this.$el.querySelectorAll('.cards-item'),
				visible = [...items].find(el => isVisible(el))
			;

			this.active = (visible) ? visible : null;
		}
	}
};
</script>

<style lang="scss">
	.cards {
		margin: 0 var(--cards-space-x);
		background-color: $color-light;

		ul {
			display: flex;
			position: relative;
			margin: 0;
			padding: 0;
			list-style: none;
		}

		li {
			flex: 0 0 auto;
		}

		svg {
			transition: $transition-duration ease-out;
			transition-property: transform, fill;
		}

		&-title {
			cursor: pointer;
			flex-grow: 1;
			margin: 1rem 0;

			span {
				display: block;
				font-size: 0.75em;
				font-weight: normal;
			}
		}

		&-grid {
			ul {
				flex-wrap: wrap;
				justify-content: center;
				margin: 0 auto;
				max-width: 1380px;
			}

			li {
				padding: 0.625rem;
			}
		}

		&-slider {
			ul {
				align-items: stretch;
				overflow-y: hidden;
				padding: 3rem 0;
				text-align: left;
				white-space: nowrap;
				scroll-behavior: smooth;
				scroll-snap-type: x mandatory;
			}

			li {
				padding: 0 0 0 1.25rem;
				white-space: normal;
				scroll-snap-align: start;

				&:first-child {
					padding-left: calc(var(--cards-space-x) * -1);
				}

				&:last-child {
					padding-right: calc(var(--cards-space-x) * -1);
				}
			}
		}

		&-item {
			transition: transform $transition-duration ease-out, box-shadow $transition-duration ease-out;
			display: flex;
			flex: 1 1 100%;
			flex-direction: column;
			padding: 1rem;
			max-width: 260px;
			height: 100%;
			background-color: $color-white;
			border-radius: 1rem;
			text-align: center;

			&:hover {
				transform: translate3d(0, -0.3rem, 0);
				box-shadow: 0 0.6rem 0.6rem -0.3rem rgba($color-black, 0.1);
			}

			&:hover,
			&.is-active {
				figure svg {
					transform: scale(1.2);
					fill: $color-brand;
				}

				.ages-time::before {
					height: 1rem;
				}
			}
		}

		&-icon {
			cursor: pointer;
			margin: 0 auto 1rem;
			max-width: 70%;

			svg {
				max-width: 100%;
				max-height: 140px;
				fill: $color-border;
			}
		}

		&-meta {
			abbr {
				cursor: help;
				margin: 0 0.25rem;

				&:hover {
					svg {
						transform: scale(1.2);
					}
				}
			}

			svg {
				width: 32px;
				height: 32px;
			}
		}

		.ages {
			margin: 1rem 2rem;

			&-time::before {
				transition: height $transition-duration ease-out;
			}
		}

		.more {
			margin: 2rem;
		}
	}
</style>
