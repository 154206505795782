export function getDimensionSize(start, end) {
	return parseFloat(start) - parseFloat(end);
}

export function getStartDimension(x, start, end) {
	return (start - parseFloat(x)) / getDimensionSize(start, end) * 100;
}

export function getEndDimension(x, start, end) {
	return (parseFloat(x) - end) / getDimensionSize(start, end) * 100;
}
