<template>
	<div class="ages">
		<div class="ages-bar">
			<div
				v-for="period in periods"
				:key="period.slug"
				:class="`ages-period is-${period.slug}`"
				:style="{width: getPeriodWidth(period)}"
				:data-start="period.start"
				:data-end="period.end"
				:title="period.name"
			/>
		</div>
		<div
			v-if="item.start && item.end"
			:class="['ages-time', {
				'is-right-open': item.end < end
			}]"
			:style="{
				marginLeft: getStartDimension(item.start),
				marginRight: (item.end > end) ? getEndDimension(item.end) : 0
			}"
		>
			<span class="ages-start">
				{{ item.start }}
			</span>
			<span class="ages-end">
				{{ item.end }}
			</span>
		</div>
	</div>
</template>

<script>
import {getStartDimension, getEndDimension} from '@/utils/dimension';


export default {
	name: 'AppAges',
	props: {
		item: {
			type: Object,
			default: () => {}
		},
		periods: {
			type: Array,
			default: () => []
		}
	},
	computed: {
		start() {
			return this.periods[0].start;
		},
		end() {
			return this.periods[this.periods.length - 1].end;
		}
	},
	methods: {
		getStartDimension(x) {
			const start = getStartDimension(x, this.start, this.end);

			return (start >= 95) ? `calc(${start}% - 3px)` : `${start}%`;
		},
		getEndDimension(x) {
			return `${getEndDimension(x, this.start, this.end)}%`;
		},
		getPeriodWidth(x) {
			const {start, end} = this;

			return `${(100 - getStartDimension(x.start, start, end) - getEndDimension(x.end, start, end))}%`;
		}
	}
};
</script>

<style lang="scss">
	.ages {
		&-bar {
			display: flex;
			overflow: hidden;
			border-radius: 1rem;
		}

		&-period {
			height: 1rem;
		}

		&-time {
			position: relative;
			min-width: 5px;
			height: 1rem;
			border-right: 1px dotted $color-border;
			border-left: 1px dotted $color-border;
			font-size: 0.75rem;

			&::before {
				content: '';
				transform: translateY(-50%);
				position: absolute;
				top: 0;
				right: 0;
				left: 0;
				height: 0.5rem;
				background-color: $color-timeline;
				border-radius: 0.5rem;
			}

			&.is-right-open::before {
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
			}
		}

		&-start,
		&-end {
			transform: translateY(-50%);
			position: absolute;
			top: 50%;
			padding: 0 0.5rem;
		}

		&-start {
			right: 100%;
		}

		&-end {
			left: 100%;
		}
	}
</style>
